<script setup>
import { ref, reactive, defineEmits } from 'vue'
import { helpers, required } from '@vuelidate/validators'
import dayjs from 'dayjs'
import errorHandler from '@/helpers/errorhandler'
import FxReportContainer from '@/components/FxReportContainer'
import ReportService from '@/services/ReportService'
import useVuelidate from '@vuelidate/core'

const laporanService = new ReportService()

let isLoadingExportExcel = ref(false)
let form = reactive({
  date_from: null,
  date_to: null,
})
let submitted = ref(false)

const rules = {
  date_from: {
    required: helpers.withMessage('Start Date is empty.', required),
  },
  date_to: {
    required: helpers.withMessage('End Date is empty.', required),
  },
}

const v$ = useVuelidate(rules, form)

// eslint-disable-next-line no-unused-vars
const emit = defineEmits(['toggle'])

function onExportExcel(isFormValid) {
  isLoadingExportExcel.value = true
  const startDate = dayjs(form.date_from).format('YYYY-MM-DD')
  const endDate = dayjs(form.date_from).format('YYYY-MM-DD')

  submitted.value = true

  if (!isFormValid) {
    isLoadingExportExcel.value = false
    return
  }

  laporanService
    .exportNationalTimesheetSummaryRangePeriod(startDate, endDate)
    .then((res) => {
      const url = window.URL.createObjectURL(new Blob([res.data]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute(
        'download',
        'Report National Timesheet Summary Range Period.xlsx'
      )
      document.body.appendChild(link)
      link.click()
    })
    .catch((err) => {
      errorHandler(err, 'Report National Timesheet Summary Range Period', this)
    })
    .finally(() => (isLoadingExportExcel.value = false))
}

function onToggle() {
  emit('toggle')
}

function resetForm() {
  submitted.value = false
  form.date_from = null
  form.end_date = null
}
</script>

<template>
  <fx-report-container
    title="Report National Timesheet Summary Range Period"
    :loading="isLoadingExportExcel"
    @toggle="onToggle"
    @export="onExportExcel"
  >
    <template #content>
      <form @submit.prevent="onExportExcel(!v$.$invalid)">
        <div class="formgrid grid">
          <div class="field col-12">
            <div class="font-bold">Filter</div>
          </div>
          <div class="field col-12 md:col-2">
            <Calendar
              v-model="v$.date_from.$model"
              dateFormat="dd/mm/yy"
              :class="{
                'p-invalid': v$.date_from.$invalid && submitted,
              }"
              placeholder="Start Date"
              class="w-full"
            />
            <small
              v-if="
                (v$.date_from.$invalid && submitted) ||
                v$.date_from.$pending.$response
              "
              class="p-error"
              >{{ v$.date_from.required.$message }}</small
            >
          </div>
          <div class="field col-12 md:col-2">
            <Calendar
              v-model="v$.date_to.$model"
              dateFormat="dd/mm/yy"
              :class="{
                'p-invalid': v$.date_to.$invalid && submitted,
              }"
              placeholder="End Date"
              class="w-full"
            />
            <small
              v-if="
                (v$.date_to.$invalid && submitted) ||
                v$.date_to.$pending.$response
              "
              class="p-error"
              >{{ v$.date_to.required.$message }}</small
            >
          </div>
          <div class="field col-12 md:col-3">
            <Button
              :loading="isLoadingExportExcel"
              type="button"
              icon="pi pi-file-excel"
              label="Export"
              class="p-button ml-2"
              @click="onExportExcel(!v$.$invalid)"
            />
            <Button
              type="reset"
              icon="pi pi-refresh"
              label="Reset"
              class="p-button-outlined ml-2"
              @click="resetForm"
            />
          </div>
        </div>
      </form>
    </template>
  </fx-report-container>
</template>
